import React from "react";
import "./services.css";
import room from "../../assests/pic2.jpg";
import { FaStar } from "react-icons/fa";
import { MdBedroomParent } from "react-icons/md";
import { PiToiletFill } from "react-icons/pi";
import { Fade } from "react-reveal";

const Services = () => {
  return (
    <>
      <section className="services">
        <div className="servicesContainer">
          <Fade left duration={1000}>
            <div className="servicesContainer_left">
              <h2>SERVICES & AMENITIES</h2>
              <h4>
                Step into our lavish homestay, where we delight in offering
                outstanding services and comforts to make your stay cozy and
                unforgettable. From delectable dining choices to serene rooms,
                we've got all the ingredients for a truly pampering experience.
              </h4>
              <div className="line"></div>
            </div>
          </Fade>

          <Fade duration={1000}>
            <div className="servicesContainer_middle">
              <div className="servicesContainer_middle_imgBox">
                <img src={room} alt="washroom" />
              </div>
            </div>
          </Fade>
          <Fade right duration={1000}>
            <div className="servicesContainer_right">
              <div className="servicesContainer_right_box">
                <div className="servicesContainer_right_box-icon">
                  <FaStar fontSize={60} />
                </div>
                <div className="servicesContainer_right_box-text">
                  <h2>Ammenities</h2>
                  <p>
                    Free Wi-Fi, Lawn, Pantry for cooking small meals, Laundary, Public
                    parking in front of the property, 24/7 CCTV security for
                    enhanced protection.
                  </p>
                </div>
              </div>
              <div className="servicesContainer_right_box">
                <div className="servicesContainer_right_box-icon">
                  <MdBedroomParent fontSize={60} />
                </div>
                <div className="servicesContainer_right_box-text">
                  <h2>Room Features</h2>
                  <p>
                    Charging Points, Mirror, Hangers, Chair, Table, Curtains,
                    TV, Fan, Cushions, Blanket, Pillows ETC. Aircon and heating
                    are both chargeable.
                  </p>
                </div>
              </div>
              <div className="servicesContainer_right_box">
                <div className="servicesContainer_right_box-icon">
                  <PiToiletFill fontSize={60} />
                </div>
                <div className="servicesContainer_right_box-text">
                  <h2>Washroom Features</h2>
                  <p>
                    Toiletries, Western Toilet Seat, Dustbins, Toilet Paper, Hot
                    & Cold Water, Shower, Towels.
                  </p>
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </section>
    </>
  );
};

export default Services;
