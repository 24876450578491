import React from "react";
import "./attraction.css";
import mussoorie from "../../assests/pic5.jpg";
import { Fade } from "react-reveal";
import { Link } from "react-router-dom";

const Attraction = () => {
  return (
    <>
      <section className="attraction">
        <div className="attractionContainer">
          <div className="attractionContainer"></div>
          <Fade left duration={1000}>
            <div className="attractionContainer_left">
              <Fade bottom cascade duration={1000}>
                <h1>Your Stay to Mussoorie</h1>
              </Fade>
              <h3>"THE QUEEN OF HILLS"</h3>
              <div className="line"></div>
              <p>
                Embark on a journey to Mussoorie, the Queen of Hills, where
                nature's beauty and tranquility crown the hills, promising an
                enchanting escape into serenity. Explore the scenic landscapes
                and relish the regal charm of this Himalayan gem. It is 27.9 km
                away and takes 58 minutes from Canal Homestay.
              </p>
              <Link to={"/attraction"}>
                <button className="btn">Explore More!</button>
              </Link>
            </div>
          </Fade>
          <Fade right duration={1000}>
            <div className="attractionContainer_right">
              <div className="attractionContainer_right-imgBox">
                <img src={mussoorie} alt="mussoorie hills" />
              </div>
            </div>
          </Fade>
        </div>
      </section>
    </>
  );
};

export default Attraction;
