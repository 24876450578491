import React from "react";
import "./features.css";

import { MdOutlineSecurity } from "react-icons/md";
import { FaWifi } from "react-icons/fa";
import { LuChefHat } from "react-icons/lu";
import { FaTree } from "react-icons/fa6";
import { Fade } from "react-reveal";

const Features = () => {
  return (
    <>
      <section className="features">
        <Fade bottom cascade duration={1000}>
          <h1>Featured Facilities</h1>
          <p>ALL YOU NEED RIGHT AT HOME</p>
        </Fade>
        <div className="features_cardContainer">
          <Fade bottom duration={1000}>
            <div className="features_cardContainer-card">
              <div className="features_cardContainer-card_icon">
                <MdOutlineSecurity fontSize={80} />
                <h2>24/7 Security</h2>
              </div>
              <div className="features_cardContainer-card_subtext">
                <p>
                  Discover tranquility with confidence at our safe and inviting
                  homestay
                </p>
              </div>
            </div>
          </Fade>
          <Fade bottom duration={1000}>
            <div className="features_cardContainer-card">
              <div className="features_cardContainer-card_icon">
                <FaWifi fontSize={80} />
                <h2>Free Wifi</h2>
              </div>
              <div className="features_cardContainer-card_subtext">
                <p>
                  All rooms have good high speed Wi-fi connectivity. watch. 24/7
                </p>
              </div>
            </div>
          </Fade>
          <Fade bottom duration={1000}>
            <div className="features_cardContainer-card">
              <div className="features_cardContainer-card_icon">
                <LuChefHat fontSize={80} />
                <h2>In House Menu Card</h2>
              </div>
              <div className="features_cardContainer-card_subtext">
                <p>
                  Craft your own small delights in our kitchen, adding a
                  personal touch to your stay.
                </p>
              </div>
            </div>
          </Fade>
          <Fade bottom duration={1000}>
            <div className="features_cardContainer-card">
              <div className="features_cardContainer-card_icon">
                <FaTree fontSize={80} />
                <h2>Eco Friendly</h2>
              </div>
              <div className="features_cardContainer-card_subtext">
                <p>
                  Embrace green living at our eco-friendly homestay, where
                  sustainability meets comfort.
                </p>
              </div>
            </div>
          </Fade>
        </div>
      </section>
    </>
  );
};

export default Features;
