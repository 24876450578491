import React from "react";
import "./Attractions.css";
import sahastradhara from "../../assests/pic8.jpg";
import fri from "../../assests/pic10.jpg";
import robbersCave from "../../assests/pic9.jpg";
import tapkeshwar from "../../assests/pic11.jpeg";
import deerPark from "../../assests/pic13.jpg";
import buddhaTemple from "../../assests/pic12.jpg";
import mosuri from "../../assests/pic5.jpg";
import jharipani from "../../assests/jharipani.jpg";
import kemptyfall from "../../assests/kempty-falls.png";
import bhattafall from "../../assests/Bhatta-Fall.jpg";
import georgeEverest from "../../assests/george-everest.jpg";
import lambidehra from "../../assests/lambidehra.jpg";
import { Fade } from "react-reveal";

const Attractions = () => {
  return (
    <>
      <header className="attractionsHeader">
        <h1>ATTRACTIONS</h1>
      </header>
      <main>
        <section className="attractionsMainSection1">
          <Fade bottom duration={1000}>
            <h2>Explore Dehradun and Beyond: Unveiling Nearby Wonders</h2>
            <p>
              "Explore the captivating charm of Dehradun, where nature and
              culture unite in a perfect blend."
            </p>
          </Fade>
          <div>
            <Fade bottom duration={1000}>
              <div className="attractionsMainSection1_container">
                <div className="attractionsMainSection1_container_imgBox">
                  <img src={mosuri} alt="Mossurrie" />
                </div>
                <div className="attractionsMainSection1_container_infoBox">
                  <h2>Mussoorie</h2>
                  <h5>27.9Km | 58mins travel</h5>
                  <p>
                    Embark on a journey to Mussoorie, just 27.9 km from our
                    homestay. Experience the Queen of Hills' enchanting charm
                    and natural beauty. After a day of exploration, return to
                    the comfort of our retreat, making it your perfect mountain
                    getaway!
                  </p>
                </div>
              </div>
            </Fade>
            <Fade bottom duration={1000}>
              <div className="attractionsMainSection1_container">
                <div className="attractionsMainSection1_container_imgBox">
                  <img src={jharipani} alt="jharipani" />
                </div>
                <div className="attractionsMainSection1_container_infoBox">
                  <h2>Jharipani Waterfall</h2>
                  <h5>13.6Km | 29mins travel</h5>
                  <p>
                    Explore Jharipani Waterfall, a scenic wonder near our
                    homestay. Immerse yourself in nature's soothing sounds and
                    return to comfort after a tranquil day. Your perfect blend
                    of adventure and relaxation awaits!
                  </p>
                </div>
              </div>
            </Fade>
            <Fade bottom duration={1000}>
              <div className="attractionsMainSection1_container">
                <div className="attractionsMainSection1_container_imgBox">
                  <img src={kemptyfall} alt="kemptyfall" />
                </div>
                <div className="attractionsMainSection1_container_infoBox">
                  <h2>Kemptyfall</h2>
                  <h5>42.6Km | 1hr 32mins travel</h5>
                  <p>
                    Discover Kempty Falls, a picturesque destination near our
                    homestay. After a rejuvenating day at Kempty Falls, return
                    to the comfort of our homestay, creating the perfect balance
                    of adventure and relaxation for your getaway!
                  </p>
                </div>
              </div>
            </Fade>
            <Fade bottom duration={1000}>
              <div className="attractionsMainSection1_container">
                <div className="attractionsMainSection1_container_imgBox">
                  <img src={bhattafall} alt="Bhtta fall" />
                </div>
                <div className="attractionsMainSection1_container_infoBox">
                  <h2>Bhattafalls</h2>
                  <h5>20.1Km | 43mins travel</h5>
                  <p>
                    Discover the enchanting Bhatta Falls near our homestay, a
                    captivating retreat surrounded by nature's beauty. After a
                    serene day of exploration, unwind in the comfort of our
                    homestay, creating memories for a perfect getaway in the lap
                    of tranquility.
                  </p>
                </div>
              </div>
            </Fade>
            <Fade bottom duration={1000}>
              <div className="attractionsMainSection1_container">
                <div className="attractionsMainSection1_container_imgBox">
                  <img src={georgeEverest} alt="geroge everest" />
                </div>
                <div className="attractionsMainSection1_container_infoBox">
                  <h2>George Everest</h2>
                  <h5>34.7Km | 1hr 14mins travel</h5>
                  <p>
                    Explore George Everest, a historic site near our homestay,
                    offering panoramic views and a glimpse into the past. After
                    a day of discovery, return to the comfort of our homestay,
                    creating a perfect blend of history and relaxation for your
                    memorable getaway!
                  </p>
                </div>
              </div>
            </Fade>
            <Fade bottom duration={1000}>
              <div className="attractionsMainSection1_container">
                <div className="attractionsMainSection1_container_imgBox">
                  <img src={lambidehra} alt="Lambidera mines" />
                </div>
                <div className="attractionsMainSection1_container_infoBox">
                  <h2>Lambi Dehra Mines</h2>
                  <h5>29.8Km | 1hr 2mins travel</h5>
                  <p>
                    Explore Lambi Dehra Mines near our homestay—a historic site
                    with unique geological features and a touch of mystery.
                    After a day of thrilling exploration, return to the comfort
                    of our homestay for a perfect blend of history, a hint of
                    horror, and relaxation.
                  </p>
                </div>
              </div>
            </Fade>
            <Fade bottom duration={1000}>
              <div className="attractionsMainSection1_container">
                <div className="attractionsMainSection1_container_imgBox">
                  <img src={sahastradhara} alt="Sahastradhara" />
                </div>
                <div className="attractionsMainSection1_container_infoBox">
                  <h2>Sahastradhara</h2>
                  <h5>9.9Km | 21mins travel</h5>
                  <p>
                    Indulge in nature's beauty at Sahastradhara, Dehradun. Known
                    for therapeutic springs and cascading waterfalls, it's a
                    perfect retreat for tranquility and rejuvenation. Immerse
                    yourself in the soothing ambiance of this natural wonder.
                  </p>
                </div>
              </div>
            </Fade>
            <Fade bottom duration={1000}>
              <div className="attractionsMainSection1_container">
                <div className="attractionsMainSection1_container_imgBox">
                  <img src={tapkeshwar} alt="Tapkeshwar mahadev temple" />
                </div>
                <div className="attractionsMainSection1_container_infoBox">
                  <h2>Tapkeshwar</h2>
                  <h5>9.6Km | 23mins travel</h5>
                  <p>
                    Tapkeshwar, Dehradun, invites you to a sacred retreat.
                    Nestled in a cave with a natural waterfall, this revered
                    Shiva temple offers a spiritual haven and a serene escape
                    into nature's embrace.
                  </p>
                </div>
              </div>
            </Fade>
            <Fade bottom duration={1000}>
              <div className="attractionsMainSection1_container">
                <div className="attractionsMainSection1_container_imgBox">
                  <img src={robbersCave} alt="Robbers Cave" />
                </div>
                <div className="attractionsMainSection1_container_infoBox">
                  <h2>Robber's Cave</h2>
                  <h5>4.8Km | 14mins travel</h5>
                  <p>
                    Embark on an enchanting journey to Robber's Cave, Dehradun.
                    Explore this scenic river cave with its mesmerizing
                    landscapes and natural beauty. A perfect destination for
                    those seeking tranquility and a touch of adventure.
                  </p>
                </div>
              </div>
            </Fade>
            <Fade bottom duration={1000}>
              <div className="attractionsMainSection1_container">
                <div className="attractionsMainSection1_container_imgBox">
                  <img src={buddhaTemple} alt="Mindrolling Monastery" />
                </div>
                <div className="attractionsMainSection1_container_infoBox">
                  <h2>Mindrolling Monastery</h2>
                  <h5>16.7Km | 40mins travel</h5>
                  <p>
                    Step into tranquility at Mindrolling Monastery, Dehradun.
                    Admire the exquisite Tibetan Buddhist architecture, soak in
                    the serene ambiance, and experience a spiritual retreat
                    surrounded by beauty and cultural richness.
                  </p>
                </div>
              </div>
            </Fade>
            <Fade bottom duration={1000}>
              <div className="attractionsMainSection1_container">
                <div className="attractionsMainSection1_container_imgBox">
                  <img src={deerPark} alt="Malsi Deer Park" />
                </div>
                <div className="attractionsMainSection1_container_infoBox">
                  <h2>Malsi Deer Park</h2>
                  <h5>4.7Km | 9mins travel</h5>
                  <p>
                    Discover the charm of wildlife at Malsi Deer Park, Dehradun.
                    Home to a variety of deer species and lush landscapes, this
                    park offers a delightful escape into nature. Perfect for a
                    leisurely stroll and connecting with the local fauna.
                  </p>
                </div>
              </div>
            </Fade>
          </div>
        </section>
      </main>
    </>
  );
};

export default Attractions;
